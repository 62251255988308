<template>
  <div>
    <div class="row d-flex justify-content-end mr-1 mb-3">
      <b-btn variant="warning" href="#/master-data/addon" class="ml-auto text-white" style="width: 7em;">Kembali</b-btn>
      <b-btn variant="primary" :href="'#/master-data/addon/' + $route.params.id + '/edit'" class="ml-2" style="width: 7em;">Edit</b-btn>
    </div>
    <b-card header-tag="header">
      <template #header>
        <b-row>
          <b-col cols="10">
            <h5 class="mb-0">{{$route.name}}</h5>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <template v-for="(m, index) in model">
          <b-col :key="index" cols="4" v-if="(typeof m.disabled === 'undefined' || !m.disabled) && m.type !== 'hidden'">
            <detail-group-input :key="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :value="m.value" :id="index">
              <template slot="content_logo" slot-scope="defaultSlotScope">
                <img :src="defaultSlotScope.colData" width="200" />
              </template>
              <template slot="content_price" slot-scope="defaultSlotScope">
                <span>{{formatCurrency(defaultSlotScope.colData)}}</span>
              </template>
              <template slot="content_is_active" slot-scope="defaultSlotScope">
                <span>{{defaultSlotScope.colData == 1 ? 'Aktif' : 'Tidak Aktif'}}</span>
              </template>
            </detail-group-input>
          </b-col>
        </template>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { setModelValue, formatModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import Addon from '@/models/Addon.js'

const addonModel = new Addon()

export default {
  components: {
    detailGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: addonModel.init()
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.isLoading = true
      addonModel.find(this.$route.params.id).then(resp => {
        this.isLoading = false
        setModelValue(this.model, resp)
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        this.isLoading = false
      })
    },
    formatCurrency (value) {
      return formatModelValue('price', value)
    },
    changeSwitch (value) {
      this.$swal.fire({
        title: 'Anda yakin?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.value) {
          let data = {}
          data['is_active'] = value
          this.$http.patch(addonModel.endpoint + '/' + this.$route.params.id, data)
          .then(response => {
            this.getDetail()
            this.$store.dispatch('notification/success', 'Addon Berhasil diperbaharui.')
          })
          .catch(error => {
            if (process.env.NODE_ENV !== 'production') {
              console.error(error)
            }
            this.isLoading = false
          })
        }
      })
    },
  }
}
</script>